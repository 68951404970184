import * as React from 'react';
import { getConfiguration } from 'core/configuration/configurationLoader';
import { WidgetLazyLoader } from 'components/common/WidgetLazyLoader';

declare global {
    namespace React {
        namespace JSX {
            interface IntrinsicElements {
                'sgwt-account-center': {
                    id?: string;
                    authentication?: string;
                    environment?: string;
                    language?: string;
                    'available-languages'?: string;
                    mode?: 'sg-markets';
                }
            }
        }
    }
}

export const AccountCenter: React.FC = () => {
    const { environment, widgetCdnBaseUrl } = getConfiguration();

    return <WidgetLazyLoader script={`${widgetCdnBaseUrl}/widgets/sgwt-account-center/v4/sgwt-account-center.js`}>
        <sgwt-account-center
            id="sgwtAccountCenter"
            language="en"
            available-languages="en"
            authentication="sg-connect-v2"
            mode="sg-markets"
            environment={environment?.toUpperCase() === 'PRODUCTION' ? undefined : environment || undefined}
        />
    </WidgetLazyLoader>;
};
