import { WidgetLazyLoader } from 'components/common/WidgetLazyLoader';
import { getConfiguration } from 'core/configuration/configurationLoader';
import * as React from 'react';

declare global {
    namespace React {
        namespace JSX {
            interface IntrinsicElements {
                'sgwt-help-center': any;
            }
        }
    }
}

export const HelpCenter: React.FC = () => {
    return <WidgetLazyLoader script={`${getConfiguration().sharedSgMarketsBaseUrl}/widgets/sgwt-help-center/v4/sgwt-help-center.js`}>
        <aside>
            <sgwt-help-center
                id="sgwtSuperHelpCenter"
                sg-connect-support="sg-connect-v2"
                application-id="content"
            />
        </aside>
    </WidgetLazyLoader>;
};
