import { AccountCenter } from './AccountCenter';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import sgLogo from 'images/SGLogo.png';

export const Header: React.FC = () => {
    return <div className="header">
        <nav className="navbar border-bottom">
            <div className="navbar-title">
                <Link to="/" className="d-flex navbar-title-link" >
                    <div className="navbar-logo">
                        <img src={sgLogo} width="32" height="32" alt="SG logo" />
                    </div>
                    <div className="d-flex border-left ml-3 align-items-center my-2">
                        <span className="ml-3">Content Management</span>
                    </div>
                </Link>
            </div>
            <AccountCenter />
        </nav>
    </div>;
};
