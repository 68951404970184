import { WidgetLazyLoader } from 'components/common/WidgetLazyLoader';
import { getConfiguration } from 'core/configuration/configurationLoader';
import React from 'react';

declare global {
    namespace React {
        namespace JSX {
            interface IntrinsicElements {
                'sgwt-splash-screen': { application: string };
            }
        }
    }
}

export const SplashScreen: React.FC = () => {
    return <WidgetLazyLoader script={`${getConfiguration().widgetCdnBaseUrl}/widgets/sgwt-splash-screen/v4/sgwt-splash-screen.js`}>
        <sgwt-splash-screen application="content" />
    </WidgetLazyLoader>;
};
