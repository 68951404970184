import { WidgetLazyLoader } from 'components/common/WidgetLazyLoader';
import { getConfiguration } from 'core/configuration/configurationLoader';
import * as React from 'react';
import { Ref } from 'react';


declare global {
    namespace React {
        namespace JSX {
            interface IntrinsicElements {
                'ic-contact-multi-picker': {
                    'ref': Ref<HTMLElement | undefined>;
                    'selected-ids'?: string;
                    'display-option'?: string;
                    'internal-only'?: string;
                    'placeholder'?: string;
                };
            }
        }
    }
}

interface Props {
    selectedIds?: string;
    displayOption?: string;
    placeholder?: string;
    internalOnly?: string;
}

export const IcMultiPicker = React.forwardRef<HTMLElement, Props>(function IcMultiPicker(props, ref) {
    return <WidgetLazyLoader script={`${getConfiguration().sharedSgMarketsBaseUrl}/ic-widgets/v1/ic-contact-multi-picker.js`}>
        <ic-contact-multi-picker
            ref={ref}
            selected-ids={props.selectedIds}
            display-option={props.displayOption}
            placeholder={props.placeholder}
            internal-only={props.internalOnly}
        />
    </WidgetLazyLoader >;
});
