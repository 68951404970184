import { WidgetLazyLoader } from 'components/common/WidgetLazyLoader';
import { getConfiguration } from 'core/configuration/configurationLoader';
import * as React from 'react';


declare global {
    namespace React {
        namespace JSX {
            interface IntrinsicElements {
                'ic-contact-single-picker': {
                    'id'?: string;
                    'placeholder'?: string;
                    'disabled'?: boolean;
                }
            }
        }
    }
}

export const IcSinglePicker = ({ id }: { id?: string }) => {
    return <WidgetLazyLoader script={`${getConfiguration().sharedSgMarketsBaseUrl}/ic-widgets/v1/ic-contact-single-picker.js`}>
        <ic-contact-single-picker
            id="inputOwner"
            placeholder=""
            disabled={true}
            readonly-id={id}
        />
    </WidgetLazyLoader>;
};
